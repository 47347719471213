import React from 'react';
import './ResultScreen.css';

const EuropeanResultScreen = ({ homeScore, awayScore, handicap, outcome }) => {
  const adjustedHome = Number(homeScore) + Number(handicap);
  const adjustedAway = awayScore; // Sem ajustes para o time visitante no handicap europeu
  
  let result = '';
  if (adjustedHome === adjustedAway) {
    result = 'Empate';
  } else if (adjustedHome > adjustedAway) {
    result = 'Vitória da Casa';
  } else {
    result = 'Vitória do Visitante';
  }

  const betResult = evaluateEuropeanHandicap(handicap, outcome, homeScore, awayScore) ? 'Você ganhou!' : 'Você perdeu.';

  return (
    <>
      <div className="modal-title">Resultado da Aposta (Handicap Europeu)</div>
      <div className="result-container">
        <div className="teams">
          <span>Casa</span>
          <span>VS</span>
          <span>Visitante</span>
        </div>
        <div className="scores">
          <input type="text" value={adjustedHome} readOnly />
          <input type="text" value={adjustedAway} readOnly />
        </div>
        <div className="result">
          <p>Resultado da sua aposta: {result}</p>
          <p>{betResult}</p>
        </div>
      </div>
    </>
  );
};

export default EuropeanResultScreen;

function evaluateEuropeanHandicap(handicap, outcome, homeScore, awayScore) {
  const adjustedHome = Number(homeScore) + Number(handicap);
  const adjustedAway = Number(awayScore);
  let result = '';

  if (adjustedHome > adjustedAway) {
    result = 'home';
  } else if (adjustedHome < adjustedAway) {
    result = 'away';
  } else {
    result = 'draw';
  }

  return result === outcome;
}