import React from 'react';
import '../modal/Modal.css';
import { CSSTransition } from 'react-transition-group';

export default function Modal({ isOpen, setModalOpen, goBack, children, title, modalHistory }) {
  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <div className="modal-background">
        <div className="modal-content">
          {title && <div className="modal-title">{title}</div>}
          <div className="close-button" onClick={() => setModalOpen(false)}>x</div>
          <div className="modal-body">
            {children}
          </div>

          <div className="buttons-container">
            <div className="modal-buttons-row">
              {modalHistory && modalHistory.length > 1 && (
                <button className="back-button" onClick={goBack}>Voltar</button>
              )}
              <button className="button-closed" onClick={() => setModalOpen(false)}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}