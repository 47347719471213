import React, { useState } from 'react';
import './CasaModal';

const CasaModal = ({ onRangeSelect }) => {
  const [rangeValue, setRangeValue] = useState(0);

  const handleRangeChange = (e) => {
    const newValue = e.target.value;
    setRangeValue(newValue);
  };

  const handleConfirmClick = () => {
    if (rangeValue !== 0) { // Validando que o usuário selecionou um valor diferente de zero
      onRangeSelect(rangeValue);
    } else {
      alert('Por favor, selecione um valor antes de continuar.'); // Uma simples mensagem de alerta. Você pode substituir por outra notificação se preferir.
    }
  };

  const valueColor = rangeValue > 0 ? 'green' : rangeValue < 0 ? 'red' : 'default';


  return (
    <div>
      {/* ... outros conteúdos do modal */}
      <div className="modal-title">Selecione a OD da aposta</div>
      <div className={`range-slider ${valueColor}`}>
        <input
          className={`rs-range ${rangeValue > 0 ? 'positive' : rangeValue < 0 ? 'negative' : 'default'}`}
          type="range"
          value={rangeValue}
          min="-5"
          max="5"
          step="0.25"
          onChange={handleRangeChange}
        />
        <div className="box-minmax">
          <span>-5</span><span>{rangeValue}</span><span>+5</span>
        </div>
      </div>
      <button onClick={handleConfirmClick}>Confirmar e Proceder</button> {/* Botão de confirmação */}

      {/* ... outros conteúdos do modal */}
    </div>
  );
};

export default CasaModal;
