import React, { useState } from 'react';
import '../nav_bar/NavBar.css';
import imagem from '../../assets/logo - central da bet-02.png';


const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div>
            <header>
                <a className="logotipo-Header" href="https://www.centraldabet.com">
                    <img style={{ width: '250px', height: '56px' }} src={imagem} alt="Logo-Central-Bet" /> 
                </a>
            </header>

            <nav className="menu-section">
                <button id="toggle-menu" className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    &#9776;
                </button>
                <ul className={isMenuOpen ? 'menu active' : 'menu'}>
                    <li className="dropdown">
                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                            Aprender a apostar <span className="arrow-down">&#9662;</span>
                        </a>
                        <ul className="submenu">

                            <li><a href="https://centraldabet.com/futebol/">Futebol</a></li>
                            <li><a href="https://centraldabet.com/basquete/">Basquete</a></li>
                            <li><a href="#">E-Soccer</a></li>
                            <li><a href="https://centraldabet.com/e-sports/">E-Sports</a></li>
                            <li><a href="https://centraldabet.com/tenis/">Tênis</a></li>
                            <li><a href="https://centraldabet.com/tenis-de-mesa/">Tênis de Mesa</a></li>
                            <li><a href="#">Automobilismo</a></li>
                            <li><a href="https://centraldabet.com/baseball/">Baseball</a></li>
                            <li><a href="#">Futsal</a></li>
                            <li><a href="#">Vôlei</a></li>
                            <li><a href="https://centraldabet.com/mma/">MMA</a></li>

                        </ul>
                    </li>
                    <li><a href="https://centraldabet.com/duvidas/">Duvidas</a></li>
                    <li><a href="https://centraldabet.com/dicionario-de-apostas/">Dicionário de Apostas</a></li>
                    <li><a href="https://centraldabet.com/quem-somos/">Quem somos</a></li>
                    <li><a href="https://centraldabet.com/#">Fale Conosco</a></li>
                </ul>
            </nav>
        </div>
    );
}

export default Navbar;