import React from 'react';
import './Footer.css';
import imagem1 from '../../assets/Assinatura-rodape-blog_202x48-1.png';
import imagem2 from '../../assets/footer_plataformansx_gold-1.png';
import imagem3 from '../../assets/Layer-0-1-150x150.png';


const Footer = () => {
    return (
        <footer className="footer custom-font">
            <div className="column">
                <div className="image-container">
                    <p className="centered-text">PARCEIROS</p>
                    <div className="image-wrapper">
                        <img src={imagem1} alt="Imagem 1" />
                        <img src={imagem2} alt="Imagem 2" />
                    </div>
                </div>
            </div>
            <div className="column column-two">
                <p>CENTRALDABET©</p>
                <p>POLÍTICA DE PRIVACIDADE</p>
            </div>
            <div className="column">
            <img src={imagem3} alt="Imagem 2" />
            </div>
        </footer>
    );
};

export default Footer;