// GoalsOptions.jsx
import React from 'react';

const GoalsOptions = ({ openSpecificModal }) => {
  return (
    <div>
      <div className="modal-title">Escolha um tipo de aposta para Gols</div>
      <div className="buttons-container">
        <div className="modal-buttons-row">
            <button onClick={() => openSpecificModal('AsianHandicapModal')}>Handicap Asiático</button>
            <button onClick={() => openSpecificModal('TotalGoalsModal')}>Total De Gols na Partida</button>
        </div>
        <div className="modal-buttons-row">
            <button onClick={() => openSpecificModal('FinalResultModal')}>Resultado Final</button>
            <button onClick={() => openSpecificModal('EuropeanHandicap')}>Handicap Euroupeu</button>
        </div>
      </div>
    </div>
  );
};

export default GoalsOptions;
