import './App.css';
import { useState } from 'react';
import Navbar from './components/nav_bar/NavBar';
import Modal from './components/modal/modal';
import GoalsOptions from './components/goals_option/GoalsOption'; // Componente que mostra opções para "GOLS"
import HandicapAsiatic from './components/handicap_asia_fut/HandicapAsiatic'// Componente que mostra opções para "Handcap Asiático"
import CasaModal from './components/CasaModal/CasaModal' // component que mostra opções para Gols > Casa Model
import ScoreModal from './components/score_modal/ScoreModal';
import ResultScreen from './components/result_screen/ResultScreen.jsx'

import ResultScreenGold from './components/result_screen/ResultScreenGold.jsx';
import ScoreModalResultGols from './components/score_modal/ScoreModalResultGols.jsx';
import FinalResultModal from './components/resuldtado_final/FinalResultModal.jsx'

import ForaModal from './components/fora_modal/ForaModal';
import TotalGoalsModal from './components/Total_gols/totalgols.jsx'

import EscanteioOption from './components/Escanteios_option/Escanteio_option';

import EuropeanHandicap from './components/European_handicap/EuropeanHandicapModel';

import EuropeanHandicapCasaModal from './components/EuropeanHandicapCasaModal/EuropeanHandicapCasaModal';
import EuropeanResultScreen from './components/result_screen/EuropeanResultScreen';
import EuropeanScoreModal from './components/score_modal/EuropeanScoreModal';

import EuropeanHandicapForaModal from './components/EuropeanHandicapForaModal/EuropeanHandicapForaModal';
import EuropeanScoreModalFora from './components/score_modal/EuropeanScoreModalFora';
import EuropeanResultScreenAway from './components/result_screen/EuropeanResultScreenAway';

import EuropeanHandicapEmpateModal from './components/EuropeanHandicapEmpateModal/EuropeanHandicapEmpateModal.jsx';
import EuropeanScoreModalEmpate from './components/score_modal/EuropeanScoreModalEmpate.jsx';
import EuropeanResultScreenDraw from './components/result_screen/EuropeanResultScreenDraw.jsx';

import Footer from './components/footer/Footer';
import Content from './components/content/Content';



function App() {
  const [score, setScore] = useState({ home: null, away: null });

  const handleRangeSelection = (value, team) => {
    setRangeValue(value);
    setHandicapTeam(team);
    setOpenModalById('ScoreModal');
  };

  const handleEuropeanScoreConfirm = (home, away) => {
    setScore({ home, away });
    setOpenModalById('EuropeanResultScreen');
  };

  const handleScoreConfirm = (home, away) => {
    setScore({ home, away });
    setOpenModalById('ResultModal'); // usando o ID do modal aqui
  };

  const [europeanHandicapTeamFora, setEuropeanHandicapTeamFora] = useState(null);
  const handleEuropeanRangeSelectionFora = (value, team) => {
    setRangeValue(value);
    setEuropeanHandicapTeamFora(team);
    setOpenModalById('EuropeanScoreModalFora');
  };

  const [europeanHandicapTeamEmpate, setEuropeanHandicapTeamEmpate] = useState(null);
  const handleEuropeanRangeSelectionEmpate = (value, team) => {
    setRangeValue(value);
    setEuropeanHandicapTeamEmpate(team);
    setOpenModalById('EuropeanScoreModalEmpate');
  };

  const handleEuropeanScoreConfirmEmpate = (home, away) => {
    setScore({ home, away });
    setOpenModalById('EuropeanResultScreenDraw');  // assumindo que você também tenha um modal para o resultado "fora"
  };


  const handleEuropeanScoreConfirmFora = (home, away) => {
    setScore({ home, away });
    setOpenModalById('EuropeanResultScreenAway');  // assumindo que você também tenha um modal para o resultado "fora"
  };


  const [europeanHandicapTeam, setEuropeanHandicapTeam] = useState(null);
  const handleEuropeanRangeSelection = (value, team) => {
    setRangeValue(value);
    setEuropeanHandicapTeam(team);
    setOpenModalById('EuropeanScoreModal');
  };

  const [rangeValue, setRangeValue] = useState(0);
  const [openModal, setOpenModal] = useState(null);
  const [handicapTeam, setHandicapTeam] = useState(null);
  const [result, setResult] = useState(null);
  const [modalHistory, setModalHistory] = useState([]);

  const closeModal = () => {
    setOpenModalById(null);
  };

  const setOpenModalById = (modalId) => {
    setModalHistory(prevHistory => [...prevHistory, modalId]);
    setOpenModal(modalId);
  };

  const goBack = () => {
    const newHistory = modalHistory.slice(0, modalHistory.length - 1);
    setModalHistory(newHistory);
    const previousModalId = newHistory[newHistory.length - 1];
    setOpenModal(previousModalId);
  };

  return (
    <div className='App'>
      <Navbar />
      <div className='padding-top'>
        <button className='button-app' onClick={() => setOpenModalById('Modal1')}>Realizar Simulado de Aposta</button>
      </div>
      <Content />


      <Modal
        isOpen={openModal !== null}
        setModalOpen={closeModal}
        goBack={goBack}
        modalHistory={modalHistory}
      >
        {openModal === 'Modal1' && (
          <>
            <div className="modal-title">Selecione um dos Times:</div>
            <div className="buttons-container">
              <div className="modal-buttons-row">
                <button onClick={() => setOpenModalById('Modal2')}>Futebol</button>
              </div>
            </div>
          </>
        )}
        {openModal === 'Modal2' && (
          <div className="buttons-container">
            <div className="modal-title">Selecione a categoria</div>
            <div className="modal-buttons-row">
              <button onClick={() => setOpenModalById('Modal4')}>Gols</button>
              <button onClick={() => setOpenModalById('Modal5')}>Escanteios</button>
            </div>
          </div>
        )}
        {openModal === 'Modal4' && <GoalsOptions openSpecificModal={setOpenModalById} />}
        {openModal === 'AsianHandicapModal' && <HandicapAsiatic openSpecificModal={setOpenModalById} />}
        {openModal === 'TotalGoalsModal' && <TotalGoalsModal setOpenModalById={setOpenModalById} />}

        {openModal === 'CasaModal' && <CasaModal setRangeValue={setRangeValue} onRangeSelect={(value) => handleRangeSelection(value, "home")} />}
        {openModal === 'ForaModal' && <ForaModal setRangeValue={setRangeValue} onRangeSelect={(value) => handleRangeSelection(value, "away")} />}
        {openModal === 'ScoreModal' && <ScoreModal onConfirm={handleScoreConfirm} />}
        {openModal === 'ResultModal' && <ResultScreen homeScore={score.home} awayScore={score.away} rangeValue={rangeValue} team={handicapTeam} />}

        {openModal === 'FinalResultModal' && <FinalResultModal setOpenModalById={setOpenModalById} setResult={setResult} />}
        {openModal === 'ScoreModalResultGols' && <ScoreModalResultGols setResult={setResult} resultType={result} setOpenModal={setOpenModalById} />}
        {openModal === 'ResultScreenGold' && <ResultScreenGold result={result} />}

        {openModal === 'EuropeanHandicap' && <EuropeanHandicap openSpecificModal={setOpenModalById} setResult={setResult} />}

        {openModal === 'EuropeanHandicapCasaModal' && <EuropeanHandicapCasaModal setRangeValue={setRangeValue} onRangeSelect={(value) => handleEuropeanRangeSelection(value, "home")} />}
        {openModal === 'EuropeanScoreModal' && <EuropeanScoreModal onEuropeanConfirm={handleEuropeanScoreConfirm} />}
        {openModal === 'EuropeanResultScreen' && <EuropeanResultScreen homeScore={score.home} awayScore={score.away} handicap={rangeValue} outcome={europeanHandicapTeam} />}

        {openModal === 'EuropeanHandicapForaModal' && <EuropeanHandicapForaModal setRangeValue={setRangeValue} onRangeSelect={(value) => handleEuropeanRangeSelectionFora(value, "away")} />}
        {openModal === 'EuropeanScoreModalFora' && <EuropeanScoreModalFora onEuropeanConfirmFora={handleEuropeanScoreConfirmFora} />}
        {openModal === 'EuropeanResultScreenAway' && <EuropeanResultScreenAway homeScore={score.home} awayScore={score.away} handicap={rangeValue} outcome={europeanHandicapTeamFora} />}

        {openModal === 'EuropeanHandicapEmpateModal' && <EuropeanHandicapEmpateModal setRangeValue={setRangeValue} onRangeSelect={(value) => handleEuropeanRangeSelectionEmpate(value, "draw")} />}
        {openModal === 'EuropeanScoreModalEmpate' && <EuropeanScoreModalEmpate onEuropeanConfirmEmpate={handleEuropeanScoreConfirmEmpate} />}
        {openModal === 'EuropeanResultScreenDraw' && <EuropeanResultScreenDraw homeScore={score.home} awayScore={score.away} handicap={rangeValue} outcome={europeanHandicapTeamEmpate} />}

        {openModal === 'Modal5' && <EscanteioOption openSpecificModal={setOpenModalById} />}

      </Modal>
      <Footer />
    </div>
  );
}

export default App;
