// GoalsOptions.jsx
import React from 'react';

const HandicapAsiatic = ({ openSpecificModal }) => {
    return (
        <div>
            <div className="modal-title">Selecione um dos Times:</div>
            <div className="buttons-container">
                <div className="modal-buttons-row">
                    <button onClick={() => openSpecificModal('CasaModal')}>Casa</button>
                    <button onClick={() => openSpecificModal('ForaModal')}>Fora</button>
                </div>
            </div>
        </div>
    );
};

export default HandicapAsiatic;