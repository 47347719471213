import React, { useState } from 'react';
import './EuropeanHandicapEmpateModal.css';

const EuropeanHandicapEmpateModal = ({ onRangeSelect }) => {
  const [homeScore, setHomeScore] = useState(1);
  const [awayScore, setAwayScore] = useState(1); // Ambos começam com valor 1

  const handleHomeScoreChange = (e) => {
    setHomeScore(Number(e.target.value));
  };

  const handleAwayScoreChange = (e) => {
    setAwayScore(Number(e.target.value));
  };

  const handleConfirmClick = () => {
    // Verifica se os valores são iguais para determinar o empate
    if (homeScore === awayScore) {
      onRangeSelect(homeScore, awayScore); // Empate é uma condição de vitória
    } else {
      alert('Apenas o resultado de empate é válido para ganhar nesta aposta.');
    }
  };

  return (
    <div className="european-handicap-empate-modal">
      <div className="modal-title">Defina os Placares para a Aposta (Handicap Europeu)</div>
      <div className={`range-slider home`}>
        <span>Placar Casa</span>
        <input
          className={`rs-range positive`}
          type="range"
          value={homeScore}
          min="1"
          max="5"
          step="1"
          onChange={handleHomeScoreChange}
        />
        <div className="box-minmax">
          <span>1</span><span>{homeScore}</span><span>+5</span>
        </div>
      </div>
      <div className={`range-slider away`}>
        <span>Placar Visitante</span>
        <input
          className={`rs-range positive`}
          type="range"
          value={awayScore}
          min="1"
          max="5"
          step="1"
          onChange={handleAwayScoreChange}
        />
        <div className="box-minmax">
          <span>1</span><span>{awayScore}</span><span>+5</span>
        </div>
      </div>
      <button onClick={handleConfirmClick}>Confirmar e Proceder</button>
    </div>
  );
};

export default EuropeanHandicapEmpateModal;
