import React, { useState } from 'react';

function ScoreModalResultGols({ setResult, resultType, setOpenModalById }) {

  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);

  const handleConfirm = () => {
    if (resultType === 'draw') {
      setHomeScore(awayScore); // Isso garante que o placar de casa seja igual ao placar do visitante
    }

    setResult({
      type: resultType,
      homeScore,
      awayScore
    });
    setOpenModalById('ResultScreenGold');
  }

  return (

    <>

      <div className="modal-title">Defina o Placar da Aposta</div>
      <div className="score-container">
        <div className="teams">
          <span>Casa</span>
          <span>VS</span>
          <span>Visitante</span>
        </div>
        <div className="scores">
          {resultType !== 'draw' && (
            <>
              <input type="number" value={homeScore} onChange={e => setHomeScore(e.target.value)} />
              <span> - </span>
              <input type="number" value={awayScore} onChange={e => setAwayScore(e.target.value)} />
            </>
          )}
        </div>
        <button onClick={handleConfirm}>Confirmar</button>

      </div>
    </>
  );
}

export default ScoreModalResultGols;