import React from 'react';

function FinalResultModal({ setOpenModalById, setResult }) {

  const handleClick = (result) => {
    if (result === 'draw') {
      // Aqui você pode definir um placar padrão para empate, por exemplo, 1-1
      setResult({ type: 'draw', homeScore: 1, awayScore: 1 });
      setOpenModalById('ResultScreenGold');
    } else {
      setResult(result);
      setOpenModalById('ScoreModalResultGols');
    }
  }
  return (
    <>
      <div className="modal-title">Resultado Final</div>
      <div className="buttons-container">
        <div className="modal-buttons-row">
          <button onClick={() => handleClick('home')}>Casa</button>
          <button onClick={() => handleClick('draw')}>Empate</button>
       </div>
        <div className="modal-buttons-row">
          <button onClick={() => handleClick('away')}>Fora</button>
        </div>
      </div>

    </>
  );
}

export default FinalResultModal;