import React from 'react';

const EuropeanHandicapCasaModal = ({ openSpecificModal }) => {
    return (
      <div>
        <div className="modal-title">Selecione uma das opções:</div>
        <div className="buttons-container">
          <div className="modal-buttons-row">
            <button onClick={() => openSpecificModal('EuropeanHandicapCasaModal')}>Casa</button>
            <button onClick={() => openSpecificModal('EuropeanHandicapEmpateModal')}>Empate</button>
            <button onClick={() => openSpecificModal('EuropeanHandicapForaModal')}>Fora</button>
          </div>
        </div>
      </div>
    );
  };

  export default EuropeanHandicapCasaModal;
