import React, { useState } from 'react';
import './ScoreModal.css';

const EuropeanScoreModalFora = ({ onEuropeanConfirmFora }) => {
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);

  const handleEuropeanConfirmFora = () => {
    onEuropeanConfirmFora(Number(homeScore), Number(awayScore));
  };

  return (
    <>
      <div className="modal-title">Defina o Placar da Aposta para Fora (Handicap Europeu)</div>
      <div className="score-container">
        <div className="teams">
          <span>Casa</span>
          <span>VS</span>
          <span>Visitante</span>
        </div>
        <div className="scores">
          <input type="number" value={homeScore} onChange={(e) => setHomeScore(Number(e.target.value))} />
          <input type="number" value={awayScore} onChange={(e) => setAwayScore(Number(e.target.value))} />
        </div>
        <button onClick={handleEuropeanConfirmFora}>Confirmar Placar</button>
      </div>
    </>
  );
};

export default EuropeanScoreModalFora;
