import React, { useState } from 'react';
const TotalGoalsModal = ({ setOpenModalById }) => {
    const [goalValue, setGoalValue] = useState(0);
  
    const handleGoalChange = (e) => {
      const newValue = e.target.value;
      setGoalValue(newValue);
    };
  
    const handleConfirmClick = () => {
      if (goalValue !== 0) {
        // Abrir o modal seguinte após selecionar o valor
        setOpenModalById('CasaModal');
      } else {
        alert('Por favor, selecione um valor de gol.');
      }
    };
  
    return (
      <div>
        <div className="modal-title">Selecione o Total na aposta</div>
  
        <div className="range-slider">
          <input
            className="rs-range"
            type="range"
            value={goalValue}
            min="0"
            max="10" // Ou outro valor que você queira usar como máximo
            step="1"
            onChange={handleGoalChange}
          />
          <div className="box-minmax">
            <span>0</span><span>{goalValue}</span><span>10</span> 
          </div>
        </div>
  
        <button onClick={handleConfirmClick}>Confirmar</button>
      </div>
    );
  };
  export default TotalGoalsModal