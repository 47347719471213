import React from 'react';
import "./ResultScreen.css"



function ResultScreenGold({ result }) {
  // Determine o status da aposta
  let status;
  if (
    (result.type === 'home' && result.homeScore > result.awayScore) ||
    (result.type === 'away' && result.awayScore > result.homeScore) ||
    (result.type === 'draw' && result.homeScore === result.awayScore)
  ) {
    status = "Ganhou";
  } else {
    status = "Perdeu";
  }

  return (
    <>
      <div className="modal-title">Resultado da Aposta</div>
      <div className="result-container">
        <div className="teams">
          <span>Casa</span>
          <span>VS</span>
          <span>Visitante</span>
        </div>
        <div className="scores">
          <input type="text" value={result.homeScore} readOnly />
          <span> - </span>
          <input type="text" value={result.awayScore} readOnly />
        </div>
        <div className="result">
          <p>Resultado da sua aposta: {status}</p>
        </div>
      </div>
    </>
  );
}


export default ResultScreenGold;