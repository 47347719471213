import React, { useState } from 'react';
import './ScoreModal.css';
const EuropeanScoreModal = ({ onEuropeanConfirm }) => {
    const [homeScore, setHomeScore] = useState(0);
    const [awayScore, setAwayScore] = useState(0);
  
    const handleEuropeanConfirm = () => {
      onEuropeanConfirm(Number(homeScore), Number(awayScore));
    };
  
    return (
      <>
        <div className="modal-title">Defina o Placar da Aposta (Handicap Europeu)</div>
        <div className="score-container">
          <div className="teams">
            <span>Casa</span>
            <span>VS</span>
            <span>Visitante</span>
          </div>
          <div className="scores">
            <input type="number" value={homeScore} onChange={(e) => setHomeScore(Number(e.target.value))} />
            <input type="number" value={awayScore} onChange={(e) => setAwayScore(Number(e.target.value))} />
          </div>
          <button onClick={handleEuropeanConfirm}>Confirmar Placar</button>
        </div>
      </>
    );
  };
  export default EuropeanScoreModal;