import React from 'react';
import './Content.css';

function Content() {
  return (
    <div className="content-container">
      <h1>Olá, seja bem-vindo à nossa calculadora de resultados!</h1>
      <p>
        Nós criamos essa ferramenta especialmente para ajudar apostadores a ganharem mais experiência em
        diferentes tipos de apostas, sem precisar arriscar dinheiro de verdade. Com a prática, você pode
        aprender como funciona cada mercado disponível nos sites de apostas e se tornar um apostador mais
        experiente. É uma ótima forma de aprender sem precisar arriscar seu dinheiro!
      </p>
      <p>
      Com a nossa calculadora, você pode fazer uma simulação para descobrir o que é preciso para ganhar em um 
      determinado mercado e esporte de aposta. A ferramenta mostra quais são os requisitos necessários para que 
      sua aposta seja considerada vencedora. Assim, você pode se preparar melhor antes de fazer sua aposta real 
      e aumentar suas chances de ganhar dinheiro. É uma forma segura e inteligente de se aventurar nas apostas
       esportivas!
      </p>
      <p>
      E se você quiser usar a calculadora agora, logo abaixo tem um tutorial rápido e fácil de como fazer a simulação.
       Não perca tempo e comece a se preparar para suas apostas!
      </p>
    </div>
  );
}

export default Content;