import React from 'react';
import './ResultScreen.css';

const ResultScreen = ({ homeScore, awayScore, rangeValue, team }) => {
  const adjustedHome = team === 'home' ? homeScore - rangeValue : homeScore;
  const adjustedAway = team === 'away' ? awayScore - rangeValue : awayScore;

  let result = '';
  if (adjustedHome === adjustedAway) {
    result = 'Empate';
  } else if ((adjustedHome > adjustedAway && team === 'home') || (adjustedHome < adjustedAway && team === 'away')) {
    result = 'Vitória';
  } else {
    result = 'Derrota';
  }


  return (
    <>
      <div className="modal-title">Resultado da Aposta</div>
      <div className="result-container">
        <div className="teams">
          <span>Casa</span>
          <span>VS</span>
          <span>Visitante</span>
        </div>
        <div className="scores">
          <input type="text" value={adjustedHome} readOnly />
          <input type="text" value={adjustedAway} readOnly />
        </div>
        <div className="result">
          <p>Resultado da sua aposta: {result}</p>
        </div>
      </div>
    </>
  );
};

export default ResultScreen;
