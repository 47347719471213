import React, { useState } from 'react';
import './EuropeanHandicapForaModal.css';  // Ajuste o nome do CSS conforme necessário

const EuropeanHandicapForaModal = ({ onRangeSelect }) => {
  const [rangeValue, setRangeValue] = useState(0);

  const handleRangeChange = (e) => {
    const newValue = e.target.value;
    setRangeValue(newValue);
  };

  const handleConfirmClick = () => {
    if (rangeValue !== 0) {
      onRangeSelect(rangeValue);
    } else {
      alert('Por favor, selecione um valor antes de continuar.');
    }
  };

  const valueColor = rangeValue > 0 ? 'green' : rangeValue < 0 ? 'red' : 'default';

  return (
<div>
      <div className="modal-title">Defina o placar de gols do time fora de casa</div>
      <div className="range-slider">
        <input
          className="rs-range"
          type="range"
          value={rangeValue}
          min="0" // Valor mínimo ajustado para 0
          max="5" // Valor máximo mantido em 5
          step="1"
          onChange={handleRangeChange}
        />
        <div className="box-minmax">
          <span>0</span><span>{rangeValue}</span><span>+5</span>
        </div>
      </div>
      <button onClick={handleConfirmClick}>Confirmar e Proceder</button>
    </div>
  );
};

export default EuropeanHandicapForaModal;
