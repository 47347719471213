import React from 'react';
import './ResultScreen.css';

const EuropeanResultScreenAway = ({ homeScore, awayScore, handicap, outcome }) => {
    // Aqui, talvez você queira ajustar awayScore em vez de homeScore
    const adjustedAway = Number(awayScore) + Number(handicap);
    const adjustedHome = homeScore;
    
    let result = '';
    if (adjustedHome === adjustedAway) {
      result = 'Empate';
    } else if (adjustedHome > adjustedAway) {
      result = 'Vitória da Casa';
    } else {
      result = 'Vitória do Visitante';
    }
  
    const betResult = evaluateEuropeanHandicapAway(handicap, outcome, homeScore, awayScore) ? 'Você ganhou!' : 'Você perdeu.';
  
    return (
        <>
        <div className="modal-title">Resultado da Aposta (Handicap Europeu)</div>
        <div className="result-container">
          <div className="teams">
            <span>Casa</span>
            <span>VS</span>
            <span>Visitante</span>
          </div>
          <div className="scores">
            <input type="text" value={adjustedHome} readOnly />
            <input type="text" value={adjustedAway} readOnly />
          </div>
          <div className="result">
            <p>Resultado da sua aposta: {result}</p>
            <p>{betResult}</p>
          </div>
        </div>
      </>
    );
  };
  
  export default EuropeanResultScreenAway;
  
  function evaluateEuropeanHandicapAway(handicap, outcome, homeScore, awayScore) {
    const adjustedAway = Number(awayScore) + Number(handicap);
    const adjustedHome = Number(homeScore);
    let result = '';
  
    if (adjustedAway > adjustedHome) {
      result = 'away';
    } else if (adjustedAway < adjustedHome) {
      result = 'home';
    } else {
      result = 'draw';
    }
  
    return result === outcome;
  }